/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnchorWallet, useAnchorWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";
import useWalletBalance from "./use-wallet-balance";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";
import { NodeWallet, programs } from "@metaplex/js";
import toast from "react-hot-toast";
import {
  Keypair,
  PublicKey,
  Transaction,
  ConfirmOptions,
  Connection,
} from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import { TOKEN_DECIMAL } from "../constant/env";
import axios from "axios";
import { BN } from '@project-serum/anchor';
import {
  STAKE_DATA_SIZE,
  DADDYSTAKING_IDL,
  COLLECTION_ADDRESS,
} from "../constant/contract";
import {
  NEXT_PUBLIC_SOLANA_NETWORK,
  NEXT_PUBLIC_STAKE_CONTRACT_ID,
} from "../constant/env";
import { printLog } from "../utils/utility";
import { sendTransactions } from "../helpers/sol/connection";
import { CreateAssociatedTokenAccount, metadata } from "@metaplex/js/lib/programs";

const {
  metadata: { Metadata },
} = programs;
const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

const getMetadataAccount = async (mint: anchor.web3.PublicKey): Promise<anchor.web3.PublicKey> => {
  const result: any = await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from('metadata'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer()
    ],
    TOKEN_METADATA_PROGRAM_ID
  );
  const metaAccount = result[0]
  return metaAccount;
}

const connection = new anchor.web3.Connection(
  NEXT_PUBLIC_SOLANA_NETWORK == "mainnet"
    ? "https://orbital-lingering-pond.solana-mainnet.quiknode.pro/de5c3bde1794fe765c51fe13fc691f39921060db/"
    : "https://metaplex.devnet.rpcpool.com"
);
const programId = new PublicKey(NEXT_PUBLIC_STAKE_CONTRACT_ID!);
const idl = DADDYSTAKING_IDL as anchor.Idl;
const confirmOption: ConfirmOptions = {
  commitment: "finalized",
  preflightCommitment: "finalized",
  skipPreflight: false,
};

const GLOBAL_AUTHORITY_SEED = "global-authority-1";
const USER_POOL_SEED = "user-pool";
const USER_POOL_DATA_SEED = "user-pool-data";
// Global Authority key : 2fq64eEiTJr3J5vSTNr8SABQRSNM1QA2AvepgsfBoFNu
const REWARD_TOKEN = "Ec3Z5W1pMUvk532FftdBzWwvSZW43LUbiYB9yLxNZShW";
const rewardMint = new PublicKey(REWARD_TOKEN);
const DAY_TIME = 60 * 60 * 24; //60 * 60 * 24; // 1 mins

const sendTransaction = async (
  transaction: Transaction,
  signers: Keypair[],
  wallet: AnchorWallet
) => {
  try {
    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash("max")
    ).blockhash;
    await transaction.setSigners(
      wallet.publicKey,
      ...signers.map((s) => s.publicKey)
    );
    if (signers.length != 0) await transaction.partialSign(...signers);
    const signedTransaction = await wallet.signTransaction(transaction);
    let hash = await connection.sendRawTransaction(
      await signedTransaction.serialize()
    );
    await connection.confirmTransaction(hash);
    toast.success("Transaction succeed.");
  } catch (err) {
    toast.error("Transaction failed. Please try again.");
  }
};

const getTokenWallet = async (
  wallet: anchor.web3.PublicKey,
  mint: anchor.web3.PublicKey
) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];
};

const getPoolInfo = async (wallet: any) => {
  const provider = new anchor.Provider(
    connection,
    wallet,
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(idl, programId, provider);
  let userPoolInfo = null;
  
  let [userPool] = await PublicKey.findProgramAddress(
    [Buffer.from(USER_POOL_SEED), wallet.publicKey.toBuffer()],
    program.programId
  );

  try {
    let existing_account = await connection.getAccountInfo(userPool);
    if (existing_account) {
       userPoolInfo = await program.account.userPool.fetch(userPool);
    }
  } catch(err) {
  }
  return userPoolInfo;
};
const getGlobalInfo = async (wallet: AnchorWallet) => {
  const provider = new anchor.Provider(
    connection,
    wallet,
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(idl, programId, provider);
  let globalInfo = null;
  // for (let stakeAccount of resp) {
  let [globalAuthority] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );
  
  try {
    globalInfo = await program.account.globalPool.fetch(globalAuthority); //
  } catch (err) {
  }
  return globalInfo;
};

const _stakeNftList = async (
  wallet: AnchorWallet,
  stakeMode: any,
  nftMintList: any
) => {
  let provider = new anchor.Provider(
    connection,
    wallet,
    anchor.Provider.defaultOptions()
  );

  let program = new anchor.Program(idl, programId, provider);
  let [userPool] = await PublicKey.findProgramAddress(
    [Buffer.from(USER_POOL_SEED), wallet.publicKey.toBuffer()],
    program.programId
  );
  // let transaction = new Transaction();
  // let signers: Keypair[] = [];

  try {
    const signersMatrix = [];
    const instructionsMatrix = [];
    let userPoolInfo = await getPoolInfo(wallet);
    let instructions: any[] = [];

    if (userPoolInfo == null) {
      instructions.push(
        program.instruction.initUserPool({
          accounts: {
            owner: wallet.publicKey,
            userPool: userPool,
            systemProgram: anchor.web3.SystemProgram.programId,
          },
        })
      );
      let keypair = anchor.web3.Keypair.generate();
      let signers = [keypair];

      signersMatrix.push(signers);
      instructionsMatrix.push(instructions);
    }
    
    const [globalAuthority, globalBump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );
    
    for (let i = 0; i < nftMintList.length; i++) {
      let instructions: any[] = [];
      let nftMint = nftMintList[i];
      
      const sourceNftAccount = nftMint.account;
      
      const metadata = await getMetadataAccount(nftMint.address);
      const [masterEdition] = await PublicKey.findProgramAddress([
          Buffer.from('metadata'),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          nftMint.address.toBuffer(),
          Buffer.from('edition')
      ], TOKEN_METADATA_PROGRAM_ID);

      instructions.push(
        program.instruction.stakeNft({
          accounts: {
            owner: wallet.publicKey,
            userPool: userPool,
            globalAuthority: globalAuthority,
            nftMint: nftMint.address,
            sourceNftAccount: sourceNftAccount,
            metadata: metadata,
            edition: masterEdition,
            metadataId: TOKEN_METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId,
          },
        })
      );
      
      // transaction.add(
      //     await program.instruction.stakeNft({
      //         accounts: {
      //             owner: wallet.publicKey,
      //             userPool: userPool,
      //             userPoolData: userPoolData,
      //             globalAuthority: globalAuthority,
      //             nftMint: nftMint.address,
      //             sourceNftAccount: sourceNftAccount,
      //             destNftAccount: destNftAccount,
      //             tokenProgram: TOKEN_PROGRAM_ID,
      //             systemProgram: anchor.web3.SystemProgram.programId,
      //         }
      //     })
      // );

      let keypair = anchor.web3.Keypair.generate();
      let signers = [keypair];

      signersMatrix.push(signers);
      instructionsMatrix.push(instructions);
    }

    await sendTransactions(
      connection,
      wallet,
      instructionsMatrix,
      signersMatrix
    );
    await delay(20000);

    toast.success("Transaction succeed.");
    return 1;
  } catch (err) {
    toast.error("Transaction failed.");
    return 0;
  }
  // await sendTransaction(transaction, signers, wallet);
};

function delay(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const stake = async (
  PoolKey: PublicKey,
  nftMint: PublicKey,
  wallet: AnchorWallet
) => {
  let provider = new anchor.Provider(connection, wallet, confirmOption);
  let program = new anchor.Program(idl, programId, provider);

  const [globalAuthority, globalBump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  const sourceNftAccount = await getTokenWallet(wallet.publicKey, nftMint);

  let transaction = new Transaction();
  let signers: Keypair[] = [];
  let [userPoolData] = await PublicKey.findProgramAddress(
    [
      Buffer.from(USER_POOL_DATA_SEED),
      wallet.publicKey.toBuffer(),
      nftMint.toBuffer(),
    ],
    program.programId
  );

  const metadata = await getMetadataAccount(new PublicKey(nftMint));

  const [masterEdition] = await PublicKey.findProgramAddress([
      Buffer.from('metadata'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      new PublicKey(nftMint).toBuffer(),
      Buffer.from('edition')
  ], TOKEN_METADATA_PROGRAM_ID);

  transaction.add(
    await program.instruction.stakeNft(0, globalBump, {
      accounts: {
        owner: wallet.publicKey,
        userPool: PoolKey,
        userPoolData: userPoolData,
        globalAuthority: globalAuthority,
        nftMint: nftMint,
        sourceNftAccount: sourceNftAccount,
        metadata: metadata,   
        edition: masterEdition, 
        metadataId: TOKEN_METADATA_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      },
    })
  );
  await sendTransaction(transaction, signers, wallet);
};

const unStake = async (nfts: any[], wallet: AnchorWallet) => {
  let provider = new anchor.Provider(
    connection,
    wallet,
    anchor.Provider.defaultOptions()
  );

  let program = new anchor.Program(idl, programId, provider);

  const [globalAuthority, globalBump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  let [userPool] = await PublicKey.findProgramAddress(
    [Buffer.from(USER_POOL_SEED), wallet.publicKey.toBuffer()],
    program.programId
  );
  
  const signersMatrix = [];
  const instructionsMatrix = [];

  // let transaction = new Transaction();
  for (let i = 0; i < nfts.length; i++) {
    let instructions: any[] = [];
    let sourceAccount = await getTokenWallet(wallet.publicKey, nfts[i].address);

   

    // transaction.add(
    //     await program.instruction.unstakeNft(globalBump, {
    //         accounts: {
    //             owner: wallet.publicKey,
    //             userPool: userPool,
    //             userPoolData: userPoolData,
    //             globalAuthority: globalAuthority,
    //             nftMint: nfts[i].address,
    //             sourceNftAccount: sourceAccount,
    //             destNftAccount: destAccount,
    //             tokenProgram: TOKEN_PROGRAM_ID,
    //         }
    //     })
    // );
    const [masterEdition] = await PublicKey.findProgramAddress([
      Buffer.from('metadata'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      new PublicKey(nfts[i].address).toBuffer(),
      Buffer.from('edition')
    ], TOKEN_METADATA_PROGRAM_ID);

    instructions.push(
      await program.instruction.unstakeNft({
        accounts: {
          owner: wallet.publicKey,
          userPool: userPool,
          globalAuthority: globalAuthority,
          nftMint: nfts[i].address,
          sourceNftAccount: sourceAccount,
          edition: masterEdition, 
          metadataId: TOKEN_METADATA_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
        }
      })
    );
    let keypair = anchor.web3.Keypair.generate();
    let signers = [keypair];

    signersMatrix.push(signers);
    instructionsMatrix.push(instructions);
  }

  // await sendTransaction(transaction, [], wallet);
  try {
    await sendTransactions(
      connection,
      wallet,
      instructionsMatrix,
      signersMatrix
    );
    await delay(20000);
    toast.success("Transaction succeed.");
    return 1;
  } catch (err) {
    toast.error("Transaction failed.");
    return 0;
  }
};

const claim = async (wallet: AnchorWallet) => {
  let provider = new anchor.Provider(connection, wallet, confirmOption);
  let program = new anchor.Program(idl, programId, provider);
  
  const [globalAuthority] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  let transaction = new Transaction();

  let [userPool] = await PublicKey.findProgramAddress(
    [Buffer.from(USER_POOL_SEED), wallet.publicKey.toBuffer()],
    program.programId
  );

  transaction.add(
    await program.instruction.claimReward({
      accounts: {
        owner: wallet.publicKey,
        userPool: userPool,
        globalAuthority: globalAuthority,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
    })
  );

  try {
    await sendTransaction(transaction, [], wallet);
  } catch (err: any) {
    printLog(err.reason || err.error?.message || err.message);
  }
}

const useNftStake = () => {
  const [balance, setBalance] = useWalletBalance();
  const anchorWallet = useAnchorWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [dailyReward, setDailyReward] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [totalStakedNFT, setTotalStakedNFT] = useState(0);
  const [rewardedTime, setRewardedTime] = useState(0);

  useEffect(() => {
    getStakedNfts();
  }, [anchorWallet, balance]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        
        if (
          !anchorWallet ||
          !anchorWallet.publicKey ||
          !anchorWallet.signAllTransactions ||
          !anchorWallet.signTransaction
        ) {
          return;
        }
  
        
        let poolInfo = await getPoolInfo(anchorWallet);
        if (poolInfo != null) {
          let days = 0;
          if (Math.floor(Date.now() / 1000) > poolInfo.rewardTime.toNumber()) {
            days =
              (Math.floor(Date.now() / 1000) - poolInfo.rewardTime.toNumber()) /
              DAY_TIME;
          }
          let reward =
            poolInfo.totalReward.toNumber() / TOKEN_DECIMAL +
            get_daily_reward(poolInfo.stakedCount) * days;
            setClaimAmount(Math.floor(reward * 100) / 100);
            //console.log('This will run every second!', get_daily_reward(poolInfo.stakedCount), Math.floor(Date.now() / 1000), poolInfo.rewardTime.toNumber());
        }
      } catch (err) {
        console.log('error', err);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [anchorWallet]);

  const getStakedNfts = async () => {
    try {
      //const stakedNftsForOwner = await getStakedNftsForOwner(anchorWallet);
      let globalInfo = await getGlobalInfo(new NodeWallet(new Keypair()));
      setTotalStakedNFT(globalInfo?.stakedCount);
      if (
        !anchorWallet ||
        !anchorWallet.publicKey ||
        !anchorWallet.signAllTransactions ||
        !anchorWallet.signTransaction
      ) {
        return;
      }
      const poolInfo = await getPoolInfo(anchorWallet);
      if (poolInfo != null) {
        setClaimedAmount(poolInfo.earnedReward.div(new BN(TOKEN_DECIMAL)).toNumber());
        setDailyReward(get_daily_reward(poolInfo.stakedCount));
        setRewardedTime(poolInfo.rewardTime.toNumber());
        let days = 0;
        if (Math.floor(Date.now() / 1000) > poolInfo.rewardTime.toNumber()) {
          days =
            (Math.floor(Date.now() / 1000) - poolInfo.rewardTime.toNumber()) /
            DAY_TIME;
        }
        let reward =
          poolInfo.totalReward.toNumber() / TOKEN_DECIMAL +
          get_daily_reward(poolInfo.stakedCount) * days;
          setClaimAmount(Math.floor(reward * 100) / 100);
      }
    } catch(err) {
      console.log('err', err)
    }
  };

  const get_daily_reward = (staked_count: number) => {
    let rest = staked_count % 8;
    let prize = Math.floor(staked_count / 8); 
    
    let daily_reward = 2 * 8 * 24 * prize;
    
    prize = Math.floor(rest / 6);
    rest = rest % 6;
    daily_reward = daily_reward +  1.75 * 6 * 24 * prize;

    prize = Math.floor(rest / 4);
    rest = rest % 4;
    daily_reward = daily_reward +  1.5 * 4 * 24 * prize;

    daily_reward = daily_reward + rest * 24;
    return daily_reward;
  };

  const stakeNftList = async (stakeMode: any, nftMintList: any) => {
    if (!anchorWallet) {
      toast.error("Connect wallet first, please.");
      return 0;
    }
    setIsLoading(true);
    try {
      const res = await _stakeNftList(anchorWallet, stakeMode, nftMintList);
      // await updateBalance(anchorWallet);
      if (res == 1) {
        const poolInfo = await getPoolInfo(anchorWallet);
        console.log("poolInfo", poolInfo);
        if (poolInfo != null) {
          setClaimedAmount(poolInfo.earnedReward.div(new BN(TOKEN_DECIMAL)).toNumber());
          setDailyReward(get_daily_reward(poolInfo.stakedCount));
          setClaimAmount(poolInfo.totalReward.div(new BN(TOKEN_DECIMAL)).toNumber());
          setRewardedTime(poolInfo.rewardTime.toNumber());
        }
      }
      let globalInfo = await getGlobalInfo(anchorWallet);
      setTotalStakedNFT(globalInfo?.stakedCount);
      setIsLoading(false);
      return res;
    } catch (err) {
      setIsLoading(false);
      return 0;
    }
  };

  const stakeNft = async (PoolKey: PublicKey, nftMint: PublicKey) => {
    if (!anchorWallet) {
      toast.error("Connect wallet first, please.");
      return;
    }

    setIsLoading(true);

    await stake(PoolKey, nftMint, anchorWallet);

    setIsLoading(false);
  };

  const unStakeNft = async (nfts: any[]) => {
    if (!anchorWallet) {
      toast.error("Connect wallet first, please.");
      return;
    }
    setIsLoading(true);
    const res = await unStake(nfts, anchorWallet);
    if (res == 1) {
      const poolInfo = await getPoolInfo(anchorWallet);
      if (poolInfo != null) {
        setClaimedAmount(poolInfo.earnedReward.div(new BN(TOKEN_DECIMAL)).toNumber()); 
        setDailyReward(get_daily_reward(poolInfo.stakedCount));
        setClaimAmount(poolInfo.totalReward.div(new BN(TOKEN_DECIMAL)).toNumber());
        setRewardedTime(poolInfo.rewardTime.toNumber());
      }
    }
    let globalInfo = await getGlobalInfo(anchorWallet);
    setTotalStakedNFT(globalInfo?.stakedCount);
    setIsLoading(false);
    return res;
  };

  const claimRewards = async () => {
    if (!anchorWallet) {
      toast.error("Connect wallet first, please.");
      return;
    }

    setIsLoading(true);
    const res = await claim(anchorWallet);
    const poolInfo = await getPoolInfo(anchorWallet);
    setClaimedAmount(poolInfo?.earnedReward.div(new BN(TOKEN_DECIMAL)).toNumber());
    setIsLoading(false);
  };

  return {
    isLoading,
    claimAmount,
    dailyReward,
    claimedAmount,
    totalStakedNFT,
    stakeNftList,
    stakeNft,
    unStakeNft,
    claimRewards,
  };
};

async function withFindOrInitAssociatedTokenAccount(
  transaction: Transaction,
  connection: Connection,
  mint: PublicKey,
  owner: PublicKey,
  payer: PublicKey,
  allowOwnerOffCurve: boolean
) {
  const associatedAddress = await splToken.Token.getAssociatedTokenAddress(
    splToken.ASSOCIATED_TOKEN_PROGRAM_ID,
    splToken.TOKEN_PROGRAM_ID,
    mint,
    owner,
    allowOwnerOffCurve
  );
  const account = await connection.getAccountInfo(associatedAddress);
  if (!account) {
    transaction.add(
      splToken.Token.createAssociatedTokenAccountInstruction(
        splToken.ASSOCIATED_TOKEN_PROGRAM_ID,
        splToken.TOKEN_PROGRAM_ID,
        mint,
        associatedAddress,
        owner,
        payer
      )
    );
  }
  return associatedAddress;
}

export default useNftStake;
